<script setup>
const props = defineProps({
  btnType: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary' | 'senary' | 'senary2'| 'septenary' | 'octonary',
  roundType: 'rounded' | ''
})

defineEmits([
  'click'
])

const type = props.btnType
const round = props.roundType
</script>
<template>
  <button
    class="
      focus:outline-none
      font-semibold
      hover:opacity-95
      px-6
      py-2
    "
    :class="{
      'common-background-pink text-white' : type === 'primary',
      'common-background-black text-white' : type === 'secondary',
      'bg-white border-2 border-gray-200 text-gray-400' : type === 'tertiary',
      'bg-pink-600 text-white' : type === 'quaternary',
      'bg-gray-200 text-gray-400' :type === 'quinary',
      'bg-white border-2 border-pink-400 common-text-pink' : type === 'senary',
      'bg-white border-2 common-border-leaf common-text-leaf' : type === 'senary2',
      'button-septenary' : type === 'septenary',
      'button-octonary' : type === 'octonary',
      'px-2 py-1 common-background-black text-white text-xs' : type === 'nonary',
      'rounded' : round === 'rounded',
      'rounded-sm' : round === 'rounded-sm',
      'rounded-full' : round === '' || round === undefined,
    }
    "
    @click = "$emit('click', 'btn clicked')"
  >
    <slot />
  </button>
</template>

<style scoped>
.button-septenary {
  background-color: #8DC121;
  color: rgba(255, 255, 255);
}
.button-octonary {
  background-color: rgba(255, 255, 255);
  color: #8DC121;
  border-width: 2px;
}
</style>
